import { ArrowForward } from '@emotion-icons/ionicons-outline/ArrowForward';
import { Button, SimpleGrid, Title } from '@mantine/core';
import Link from 'next/link';
import React from 'react';
import { RecentlyViewedData, useRecentlyViewed } from '../utils/useRecentlyViewed';
import { isEmpty } from 'lodash';

const RecentLink = ({ data, id }: { data: RecentlyViewedData; id: number }) => {
  return (
    <Link legacyBehavior href={data.url} passHref>
      <Button
        id={`recently-viewed-button-${id}`}
        component="a"
        rightIcon={<ArrowForward width={12} />}
        variant="default"
        size="xs"
        fullWidth
        styles={{
          inner: {
            justifyContent: 'space-between',
          },
        }}
      >
        {data.label}
      </Button>
    </Link>
  );
};

const RecentlyViewed = () => {
  const viewed = useRecentlyViewed();
  if (isEmpty(viewed)) {
    return null;
  }
  return (
    <>
      <Title order={6}>Pick Up Where You Left Off...</Title>
      <SimpleGrid cols={1}>
        {viewed?.map((v, idx) => {
          return <RecentLink data={v} key={idx} id={idx} />;
        })}
      </SimpleGrid>
    </>
  );
};

export default RecentlyViewed;
